<template>
    <div class="ytm-modal-wrapper" ref="wrapper">
        <div class="ytm-modal">
            <div class="ytm-modal-body">
                <svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-modal-x" @click="$emit('reset_modal')">
                    <circle cx="16.5" cy="16.5" r="16.5" fill="#DDDDDD"/>
                    <path d="M11 11.0991L22.6636 21.6026" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    <path d="M22.0811 10.5175L11.5811 22.1842" stroke="white" stroke-width="2" stroke-linecap="round"/>
                </svg>
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    mounted() {
        document.body.style.overflow = 'hidden';
        document.addEventListener('click', evt => {
            if (evt.target === this.$refs.wrapper) {
                this.$emit('reset_modal');
            }
        });
    },
    beforeUnmount() {
        document.body.style.overflow = 'scroll';
    },
};
</script>

<style scoped>
.ytm-modal-wrapper {
    z-index: 1001;
    background-color: rgba(79, 79, 79, 0.59);
    display: flex;
    position: fixed;
    overflow: scroll;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 2rem 0 7rem 0;
}

.ytm-modal {
    position: relative;
    margin: auto;
    z-index: 1002;
    width: 65%;
    min-width: 320px;
    background: #FFFFFF;
    border-radius: 1rem;
}

.ytm-modal-body {
    position: relative;
    padding: 2rem 6rem;
}

@media only screen and (max-width: 600px) {
    .ytm-modal {
        width: 90%;
    }

    .ytm-modal-body {
        padding: 2rem 1rem;
    }
}

.ytm-modal-x {
    position: absolute;
    top: 0.9rem;
    right: 0.9rem;
    height: 1.5rem;
    cursor: pointer;
}
</style>