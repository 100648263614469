<template>
    <div :class="{'ytm-select': true, 'ytm-fill-width': fillWidth}">
        <div :class="{'ytm-selected-value': true, 'ytm-fill-width': fillWidth}" tabindex="-1" @focus="showOptions = true" @blur="showOptions = false">
            {{selectedValue}}
            <svg viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-select-icon">
                <path d="M9.36847 17.8356C10.2312 19.3299 12.388 19.3299 13.2507 17.8356L21.5005 3.54665C22.3632 2.05238 21.2848 0.184542 19.5593 0.184542H3.05984C1.3344 0.184542 0.256004 2.05238 1.11872 3.54666L9.36847 17.8356Z" fill="#9E9E9E"/>
            </svg>
        </div>
        <div v-if="showOptions" class="ytm-select-options">
            <div v-for="(option, i) in options" :key="option.value">
                <p class="ytm-default-text ytm-select-option" @mousedown="selectOption(option)">
                    {{option.desc}}
                </p>
                <hr v-if="i + 1 !== options.length" style="margin: 0">
            </div>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";

export default {
    name: 'YTMSelect',
    props: {
        modelValue: {},
        options: {
            type: Array,
            required: true,
        },
        defaultValue: {
            type: String,
            default: ' ',
        },
        fillWidth: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        selectedValue() {
            for (const option of this.options) {
                if (option.value === this.modelValue) {
                    return option.desc;
                }
            }
            return this.defaultValue;
        },
    },
    data() {
        return {
            showOptions: false,
        };
    },
    methods: {
        selectOption(option) {
            this.$emit('update:modelValue', option.value);
            this.showOptions = false;
        },
    },
};
</script>

<style scoped>
.ytm-fill-width {
    width: 100%;
}

.ytm-select {
    position: relative;
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
}

.ytm-selected-value {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    padding: 0.75rem 4rem 0.75rem 1.25rem;
    background: #F3F3F3;
    font-size: 0.9rem;
    font-weight: 300;
    line-height: 116.6%;
    color: rgba(57, 57, 57, 0.87);
    border-radius: 0.6rem;
    user-select: none;
    cursor: pointer;
}

.ytm-select-options {
    position: absolute;
    left: 0;
    right: 0;
    max-height: 380px;
    overflow: scroll;
    padding: 0.2rem 0;
    background-color: #FFFFFF;
    border-radius: 0.6rem;
    box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.25);
    z-index: 1;
}

.ytm-select-option {
    padding: 0.5rem 1.25rem;
    font-size: 0.95rem;
    background-color: #FFFFFF;
    user-select: none;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 0.5rem;
}

.ytm-select-option:hover {
    background-color: #F3F3F3;
}

.ytm-select-icon {
    position: absolute;
    height: 1rem;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}
</style>